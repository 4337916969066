import { SkeletonList } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UsePropertyHook, useConfig } from "@/hooks";
import { PropertyDto, typeOfPropertyToLabelMap } from "@/types";
import { Grid, Table, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import {
  IconBath,
  IconBed,
  IconBuildingEstate,
  IconCalendar,
  IconDimensions,
  IconGavel,
  IconHome2,
  IconMapPin,
  IconRectangle,
  TablerIcon,
  IconFridge,
  IconToolsKitchen,
  IconFlame,
  IconRipple,
  IconAirConditioning,
  IconTemperaturePlus,
} from "@tabler/icons";
import PropertyBasicHomeStatsHeader from "./PropertyBasicHomeStatsHeader";

interface Props {
  usePropertyInstance: UsePropertyHook;
  isReadOnly?: boolean;
}

interface PropertyBasicHomeStatItem {
  label: string;
  field: keyof PropertyDto;
  Icon: TablerIcon;
  formatValue?: (value: PropertyDto[keyof PropertyDto]) => string;
}

const propertyBasicHomeStatItems: Array<PropertyBasicHomeStatItem> = [
  {
    label: "Type of Property",
    field: "type",
    Icon: IconBuildingEstate,
    formatValue: (value) => typeOfPropertyToLabelMap[value],
  },
  {
    label: "Beds",
    field: "beds",
    Icon: IconBed,
  },
  {
    label: "Baths",
    field: "baths",
    Icon: IconBath,
  },
  {
    label: "Sq Ft",
    field: "sqft",
    Icon: IconDimensions,
  },
  {
    label: "Style",
    field: "style",
    Icon: IconHome2,
  },
  {
    label: "Year Built",
    field: "built",
    Icon: IconCalendar,
  },
  {
    label: "Lot Size (Acre)",
    field: "lotSizeAcre",
    Icon: IconRectangle,
  },
  {
    label: "County Name",
    field: "county",
    Icon: IconMapPin,
  },
  {
    label: "HOA Name",
    field: "hoaName",
    Icon: IconGavel,
  },
  {
    label: "HVAC Furnace Age",
    field: "hvacFurnaceAge",
    Icon: IconTemperaturePlus,
  },
  {
    label: "HVAC AC Age",
    field: "hvacAcAge",
    Icon: IconAirConditioning,
  },
  {
    label: "Water Heater Age",
    field: "waterHeaterAge",
    Icon: IconRipple,
  },
  {
    label: "Stove Range Age",
    field: "stoveRangeAge",
    Icon: IconFlame,
  },
  {
    label: "Refrigerator Age",
    field: "refrigeratorAge",
    Icon: IconFridge,
  },
  {
    label: "Dishwasher Age",
    field: "dishwasherAge",
    Icon: IconToolsKitchen,
  },
];

const splitArrayIntoGroupsOfTwo = <T extends any>(arr: T[]): T[][] =>
  arr.reduce<T[][]>((result, value, index) => (index % 2 === 0 ? result.push([value]) : result[result.length - 1].push(value), result), []);

function PropertyBasicHomeStats({ usePropertyInstance, isReadOnly = false }: Props) {
  const { property, error, loading } = usePropertyInstance;

  const theme = useTheme();
  const config = useConfig();

  const splitArray = splitArrayIntoGroupsOfTwo<PropertyBasicHomeStatItem>(propertyBasicHomeStatItems);

  return (
    <MainCard title={<PropertyBasicHomeStatsHeader isReadOnly={isReadOnly} usePropertyInstance={usePropertyInstance} />}>
      {loading && <SkeletonList count={4} height={20} />}
      {error && (
        <Typography color="error">
          <i>{error}</i>
        </Typography>
      )}
      {property && (
        <Table>
          <TableBody>
            {splitArray.map((items, i) => (
              <TableRow key={i}>
                {items.map((item) => (
                  <TableCell key={item.field}>
                    <Grid container flexWrap="nowrap" gap={2}>
                      <Grid item>
                        <div
                          style={{
                            padding: "8px 8px 3px 8px",
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: config.borderRadius,
                          }}
                        >
                          <item.Icon size={24} color={theme.palette.primary.dark} />
                        </div>
                      </Grid>
                      <Grid item container direction="column" gap={1}>
                        <Grid item>
                          <Typography variant="h5">{item.label}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {property?.[item.field]
                              ? item?.formatValue
                                ? item.formatValue(property[item.field])
                                : property[item.field]
                              : "---"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </MainCard>
  );
}

export default PropertyBasicHomeStats;
